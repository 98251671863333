/*
 * @Author: zhaoyang
 * @Date: 2024-10-18 13:03:12
 * @Last Modified by: zhaoyang
 * @Last Modified time: 2024-10-18 13:36:32
 */
import { isIOS } from './webview/ua';
import WebviewInterface from './webview/yqg-webview-interface';
async function getDeviceToken() {
    try {
        const headerInfo = await WebviewInterface.getHeaderInfo() || '{}';
        const formatHeaderInfo = JSON.parse(headerInfo);
        let deviceToken = formatHeaderInfo['YQG-PLATFORM-DEVICE-TOKEN'];
        if (isIOS(navigator.userAgent)) {
            deviceToken = JSON.parse(formatHeaderInfo['YQG-PLATFORM-ENVIRONMENT-INFO'])?.deviceUniqueId;
        }
        return deviceToken;
    }
    catch (error) {
        // ignore
    }
}
export default getDeviceToken;
