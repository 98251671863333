<!--
 * @Author: zhaoyang
 * @Date: 2023-06-21 11:51:50
 * @Last Modified by: zhaoyang
 * @Last Modified time: 2024-11-01 17:45:37
-->

<template>
    <div id="app">
        <router-view />
        <ec-webview-loading-circle v-show="!!reqCount" />
    </div>
</template>

<script>
import {Toast} from 'vant';
import Vue from 'vue';

import {isServer} from 'ssr-common/constant/config';
import {genWebDeviceToken} from 'ssr-common/util/browser-util';
import getDeviceToken from 'ssr-common/util/get-device-token';
import initRealTimeEventLog from 'ssr-common/util/real-time-log';
import WebviewInterface from 'ssr-common/util/webview/yqg-webview-interface';
import YqgCookie from 'ssr-common/util/yqg-cookie';
import interceptor from 'ssr-common/vue/mixin/interceptor';
import parseUa from 'ssr-common/vue/mixin/parse-ua';
import http from 'ssr-common/vue/vue-http';

import component from 'easycash/common/component';
import EcWebviewLoadingCircle from 'easycash/common/component/ec-webview-loading-circle';
import directive from 'easycash/common/directive';
import businessData from 'easycash/common/mixin/business-data';
import eventTrackingCache from 'easycash/common/mixin/event-tracking-cache';
import uploadEvent from 'easycash/common/mixin/upload-event';

const {common} = http.defaults.headers;
common['YQG-PLATFORM-SDK-TYPE'] = 'IDN_YQD';
common['YQG-PLATFORM-DEVICE-TOKEN'] = genWebDeviceToken();
common.PlatformType = 'WEB';

const realTimeLog = initRealTimeEventLog('easycash', 'Web_EC_IND', 'IDN');

Vue.use(component);
Vue.use(directive);

export default {
    name: 'Easycash',

    mixins: [
        businessData,
        interceptor,
        uploadEvent,
        parseUa(),
        eventTrackingCache
    ],

    components: {
        // eslint-disable-next-line vue/no-unused-components
        Toast,
        EcWebviewLoadingCircle
    },

    async created() {
        common['YQG-PLATFORM-LANGUAGE'] = this.$i18n.locale;
        Vue.prototype.$app = this;

        if (!isServer()) {
            const deviceToken = await getDeviceToken();

            if (deviceToken) {
                common['YQG-PLATFORM-DEVICE-TOKEN'] = deviceToken;
                YqgCookie.setDeviceToken(deviceToken, {
                    expires: 365
                });
            }
        }
    },

    beforeMount() {
        Toast.allowMultiple();
    },

    methods: {
        realTimeLog,
        enableLoading() {
            const {$route: {path}} = this;

            return /^\/webview\/loan\/*/i.test(path);
        },

        $routerPush(route) {
            let href = '';
            if (typeof route === 'object' && route.link) {
                href = route.link;
            } else {
                href = this.$router.resolve(typeof route === 'string' ? {name: route} : route).href;
            }

            WebviewInterface.openWebview(href);
        }
    }
};
</script>

<style lang="scss">
@import "~easycash/common/style/style.scss";
</style>
