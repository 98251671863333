/**
 * @Author: xiaoyaqu
 * @Date: 2019-05-28 15:38:53.379
 * @Last Modified by: ruiwang
 * @Last Modified time: 2023-12-14 18:20:50
 */

import EcImage from 'ssr-common/vue/component/ec-image';
import EcLayout from 'ssr-common/vue/component/ec-layout';

import Filters from '../filter';
import EcWebviewLoadingCircle from './ec-webview-loading-circle';

export default {
    install(Vue) {
        Vue.use(Filters);
        Vue.component('ec-layout', EcLayout);
        Vue.component('ec-image', EcImage);
        Vue.component('ec-webview-loading-circle', EcWebviewLoadingCircle);
        Vue.component('yqg-md-preview', () => import('ssr-common/vue/component/yqg-md-preview'));
    }
};
