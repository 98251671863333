/*
 * @Author: chengyuzhang
 * @Date: 2021-03-11 13:42:52
 * @Last Modified by: zhaoyang
 * @Last Modified time: 2024-11-04 01:06:33
 */

import {isServer} from 'ssr-common/constant/config';

import genKey from 'easycash/common/util/gen-key';

const getKey = () => {
    let userKey = window.localStorage.getItem('userKey');
    if (!userKey) {
        userKey = genKey();
        window.localStorage.setItem('userKey', userKey);
    }

    return userKey;
};

const elementContent = {
    userKey: isServer() ? '' : getKey()
};

export default {
    OvoPageEnter: {
        tag: 'OvoPageEnter',
        businessEvent: 'Easycash_OvoPage_Enter',
        elementType: 'Button',
        eventType: 'P'
    },
    TiedCardGuidePageEnter: {
        tag: 'TiedCardGuidePageEnter',
        businessEvent: 'Easycash_TiedCardGuidePage_Enter',
        elementType: 'Button',
        eventType: 'P'
    },
    HowToLoanNewPageEnter: {
        tag: 'HowToLoanNewPageEnter',
        businessEvent: 'Easycash_HowToLoanNewPage_Enter',
        elementType: 'Button',
        eventType: 'P'
    },
    AgreementPageEnter: {
        tag: 'AgreementPageEnter',
        businessEvent: 'Agreement_Page_Enter',
        elementType: 'Button',
        eventType: 'P'
    },
    AgreementPdfPageEnter: {
        tag: 'AgreementPdfPageEnter',
        businessEvent: 'Agreement_Pdf_Page_Enter',
        elementType: 'Button',
        eventType: 'P'
    },
    StaticAgreementPageEnter: {
        tag: 'StaticAgreementPageEnter',
        businessEvent: 'Static_Agreement_Page_Enter',
        elementType: 'Button',
        eventType: 'P'
    },
    AgreementSignButton: {
        tag: 'AgreementSignButton',
        businessEvent: 'Agreement_Sign_Button',
        elementType: 'Button',
        eventType: 'C'
    },
    InvitationPageEnter: {
        tag: 'InvitationPageEnter',
        businessEvent: 'Easycash_InvitationPage_Enter',
        elementType: 'Button',
        eventType: 'P'
    },
    InvitationPageEnterV2: {
        tag: 'InvitationPageEnterV2',
        businessEvent: 'Easycash_InvitationPage_Enter_V2',
        elementType: 'Button',
        eventType: 'P'
    },
    InvitationShareButton: {
        tag: 'InvitationShareButton',
        businessEvent: 'Easycash_Invitation_ShareButton',
        elementType: 'Button',
        eventType: 'C'
    },
    InvitationFacebookButton: {
        tag: 'InvitationFacebookButton',
        businessEvent: 'Easycash_Invitation_FacebookButton',
        elementType: 'Button',
        eventType: 'C'
    },
    InvitationSmsButton: {
        tag: 'InvitationSmsButton',
        businessEvent: 'Easycash_Invitation_SmsButton',
        elementType: 'Button',
        eventType: 'C'
    },
    InvitationWhatsAppButton: {
        tag: 'InvitationWhatsAppButton',
        businessEvent: 'Easycash_Invitation_WhatsAppButton',
        elementType: 'Button',
        eventType: 'C'
    },
    InvitationMailButton: {
        tag: 'InvitationMailButton',
        businessEvent: 'Easycash_Invitation_MailButton',
        elementType: 'Button',
        eventType: 'C'
    },
    InvitationFaceButton: {
        tag: 'InvitationFaceButton',
        businessEvent: 'Easycash_Invitation_FaceButton',
        elementType: 'Button',
        eventType: 'C'
    },
    InvitationCopyButton: {
        tag: 'InvitationCopyButton',
        businessEvent: 'Easycash_Invitation_CopyButton',
        elementType: 'Button',
        eventType: 'C'
    },
    InvitationShareCancelButton: {
        tag: 'InvitationShareCancelButton',
        businessEvent: 'Easycash_Invitation_ShareCancelButton',
        elementType: 'Button',
        eventType: 'C'
    },
    InvitationRouteDetailButton: {
        tag: 'InvitationRouteDetailButton',
        businessEvent: 'Easycash_Invitation_RouteDetailButton',
        elementType: 'Button',
        eventType: 'C'
    },
    InvitationRuleButton: {
        tag: 'InvitationRuleButton',
        businessEvent: 'Easycash_Invitation_RuleButton',
        elementType: 'Button',
        eventType: 'C'
    },
    subInvitationRuleButton: {
        tag: 'subInvitationRuleButton',
        businessEvent: 'Easycash_subInvitation_RuleButton',
        elementType: 'Button',
        eventType: 'C'
    },
    InvitationCopyCodeButton: {
        tag: 'InvitationCopyCodeButton',
        businessEvent: 'Easycash_Invitation_CopyCodeButton',
        elementType: 'Button',
        eventType: 'C'
    },
    InvitationDetailPageEnter: {
        tag: 'InvitationDetailPageEnter',
        businessEvent: 'Easycash_InvitationDetailPage_Enter',
        elementType: 'Button',
        eventType: 'P'
    },
    InvitationDetailPageEnterV2: {
        tag: 'InvitationDetailPageEnterV2',
        businessEvent: 'Easycash_InvitationDetailPage_Enter_V2',
        elementType: 'Button',
        eventType: 'P'
    },
    InvitationDetailShareButton: mobileNumber => ({
        tag: 'InvitationDetailShareButton',
        businessEvent: 'Easycash_InvitationDetail_ShareButton',
        elementType: 'Button',
        eventType: 'C',
        elementContent: mobileNumber
    }),
    InvitationRetainConfirmButton: {
        tag: 'InvitationRetainConfirmButton',
        businessEvent: 'Easycash_Invitation_RetainConfirmButton',
        elementType: 'Button',
        eventType: 'C'
    },
    InvitationRetainCloseButton: {
        tag: 'InvitationRetainCloseButton',
        businessEvent: 'Easycash_Invitation_RetainCloseButton',
        elementType: 'Button',
        eventType: 'C'
    },
    ActivityRegisterPageEnter: {
        tag: 'ActivityRegisterPageEnter',
        businessEvent: 'Easycash_ActivityRegisterPage_Enter',
        elementType: 'Button',
        eventType: 'P'
    },
    ActivityLuckyNumberPageEnter: (activityId, userStatus) => ({
        tag: 'ActivityLuckyNumberPageEnter',
        businessEvent: 'Easycash_ActivityLuckyNumberPage_Enter',
        elementType: 'Button',
        eventType: 'P',
        elementContent: {activityId, userStatus}
    }),
    RenewalInstructionsPageEnter: {
        tag: 'RenewalInstructionsPageEnter',
        businessEvent: 'Easycash_RenewalInstructionsPage_Enter',
        elementType: 'Button',
        eventType: 'P'
    },
    PDFAgreementPageEnter: (brand, time) => ({
        tag: 'PDFAgreementPageEnter',
        businessEvent: `${brand.toUpperCase()}_PDFAgreementPage_Enter`,
        elementType: 'Button',
        eventType: 'P',
        elementContent: {time}
    }),
    AdditionalInfoPageEnter: {
        tag: 'AdditionalInfoPageEnter',
        businessEvent: 'Easycash_AdditionalInfoPage_Enter',
        elementType: 'Button',
        eventType: 'P'
    },
    AdditionalInfoUploadPageEnter: {
        tag: 'AdditionalInfoUploadPageEnter',
        businessEvent: 'Easycash_AdditionalInfoUploadPage_Enter',
        elementType: 'Button',
        eventType: 'P'
    },
    AdditionalInfoInputPageEnter: {
        tag: 'AdditionalInfoInputPageEnter',
        businessEvent: 'Easycash_AdditionalInfoInputPageEnter_Enter',
        elementType: 'Button',
        eventType: 'P'
    },
    NavBarBackButton: {
        tag: 'NavBarBackButton',
        businessEvent: 'Easycash_NavBar_BackButton',
        elementType: 'Button',
        eventType: 'C'
    },
    PreventFraudPageEnter: {
        tag: 'PreventFraudPageEnter',
        businessEvent: 'Easycash_PreventFraudPage_Enter',
        elementType: 'Button',
        eventType: 'P'
    },
    PayWishPageEnter: {
        tag: 'PayWishPageEnter',
        businessEvent: 'Easycash_PayWishPage_Enter',
        elementType: 'Button',
        eventType: 'P'
    },
    PayWishDetailPageEnter: {
        tag: 'PayWishDetailPageEnter',
        businessEvent: 'Easycash_PayWishDetailPage_Enter',
        elementType: 'Button',
        eventType: 'P'
    },
    ImgPageEnter: {
        tag: 'ImgPageEnter',
        businessEvent: 'Easycash_ImgPage_Enter',
        elementType: 'Button',
        eventType: 'P'
    },
    newYearActivityGoldButton: {
        tag: 'GoldButton',
        businessEvent: 'Easycash_NewYearActivity_Gold_Button',
        elementType: 'Button',
        eventType: 'C'
    },
    newYearActivityShowRuleButton: {
        tag: 'ShowRuleButton',
        businessEvent: 'Easycash_NewYearActivity_Show_Rule_Button',
        elementType: 'Button',
        eventType: 'C'
    },
    newYearActivityCloseRuleButton: {
        tag: 'CloseRuleButton',
        businessEvent: 'Easycash_NewYearActivity_Close_Rule_Button',
        elementType: 'Button',
        eventType: 'C'
    },
    newYearActivityWinnersButton: {
        tag: 'WinnersButton',
        businessEvent: 'Easycash_NewYearActivity_Winners_Button',
        elementType: 'Button',
        eventType: 'C'
    },
    ProtocolPageEnter: {
        tag: 'ProtocolPageEnter',
        businessEvent: 'Protocol_Page_Enter',
        elementType: 'Button',
        eventType: 'P'
    },
    // 返回
    ProtocolBack: {
        tag: 'ProtocolBack',
        businessEvent: 'Protocol_Back_Button',
        elementType: 'Button',
        eventType: 'C'
    },
    // 注册
    ProtocolRegister: {
        tag: 'ProtocolRegister',
        businessEvent: 'Protocol_Register_Button',
        elementType: 'Button',
        eventType: 'C'
    },
    // 隐私
    ProtocolPrivacy: {
        tag: 'ProtocolPrivacy',
        businessEvent: 'Protocol_Privacy_Button',
        elementType: 'Button',
        eventType: 'C'
    },
    // 授权
    ProtocolAuth: {
        tag: 'ProtocolAuth',
        businessEvent: 'Protocol_Auth_Button',
        elementType: 'Button',
        eventType: 'C'
    },
    // 滚到底部
    ProtocolScroll: {
        tag: 'ProtocolScroll',
        businessEvent: 'Protocol_Scroll_Button',
        elementType: 'Button',
        eventType: 'C'
    },
    // 点击确认
    ProtocolComfirm: {
        tag: 'ProtocolComfirm',
        businessEvent: 'Protocol_Comfirm_Button',
        elementType: 'Button',
        eventType: 'C'
    },
    landingRegisterPageEnter: {
        tag: 'landingRegisterPageEnter',
        businessEvent: 'Easycash_landingRegisterPage_Enter',
        elementType: 'Button',
        eventType: 'P'
    }
};
